import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import HeroOther from "../components/hero-other";
import { checkPropertiesForNull } from "../utils/object";
import uuid4 from "uuid4";
import { SafeHtmlParser } from "../components/safe-html-parser";

const HowToFindUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: wpPage(slug: { eq: "how-to-find-us" }) {
        seoFieldGroups {
          localBusinessSchema
          metaDescription
          metaTitle
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        findUsPageFieldGroups {
          findUsBannerSection {
            heading
            subHeading
            description
            image {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            cta1 {
              target
              title
              url
            }
            cta2 {
              target
              title
              url
            }
          }
          findUsGoogleMapSection {
            heading
            subHeading
            map
          }
          findUsWhereSection {
            heading
            subHeading
            items {
              heading
              description
            }
          }
        }
      }
      # allInstagramContent(limit: 6, sort: { fields: timestamp, order: DESC }) {
      #   nodes {
      #     localFile {
      #       childImageSharp {
      #         gatsbyImageData(
      #           formats: [AUTO, WEBP]
      #           quality: 100
      #           transformOptions: { cropFocus: CENTER, fit: CONTAIN }
      #           layout: CONSTRAINED
      #           placeholder: BLURRED
      #         )
      #       }
      #     }
      #     caption

      #     permalink
      #     timestamp
      #   }
      # }

      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, findUsPageFieldGroups },
    site,
  } = data;

  const { findUsBannerSection, findUsGoogleMapSection, findUsWhereSection } =
    findUsPageFieldGroups;

  const siteUrl = site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "How To Find Us",
        item: {
          url: `${siteUrl}/how-to-find-us`,
          id: `${siteUrl}/how-to-find-us`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/how-to-find-us`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {findUsBannerSection &&
            !checkPropertiesForNull(findUsBannerSection, ["heading"]) && (
              <HeroOther
                smallHeading={findUsBannerSection?.subHeading}
                title={findUsBannerSection?.heading}
                description={
                  <>
                    <SafeHtmlParser
                      htmlContent={findUsBannerSection?.description}
                    />
                  </>
                }
                externalLink={findUsBannerSection?.cta1.target === "_blank"}
                button1Text={findUsBannerSection?.cta1?.title}
                button1Link={findUsBannerSection?.cta1?.url}
                button2Text={findUsBannerSection?.cta2?.title}
                button2Link={findUsBannerSection?.cta2?.url}
                backgroundImage={
                  findUsBannerSection.image.localFile.childImageSharp
                    .gatsbyImageData
                }
              />
            )}

          {findUsWhereSection &&
            !checkPropertiesForNull(findUsWhereSection, ["heading"]) && (
              <section className="py-5 bg-white py-lg-7">
                <Container>
                  <Row className="g-5 gx-lg-7 align-items-center justify-content-center">
                    <Col lg={8}>
                      {findUsWhereSection?.subHeading && (
                        <p className="text-uppercase text-center text-secondary pb-2 mb-0 ssp-semibold">
                          {findUsWhereSection?.subHeading}
                        </p>
                      )}

                      <h2 className="line-heading-dark text-center mb-5 fs-1">
                        {findUsWhereSection?.heading}
                      </h2>
                    </Col>
                  </Row>
                  {findUsWhereSection?.items &&
                    findUsWhereSection?.items.length > 0 && (
                      <Row>
                        <Col>
                          <Row className="g-5 h-lg-100">
                            {findUsWhereSection?.items.map((item) => (
                              <Col
                                style={{ minHeight: "100%" }}
                                lg={4}
                                key={uuid4()}
                              >
                                <div className="bg-primary h-100 py-5 px-4 px-xl-6 text-center">
                                  <h3 className="text-uppercase text-white fs-4 pb-3">
                                    {item.heading}
                                  </h3>
                                  {item.description && (
                                    <div className="text-white">
                                      <SafeHtmlParser
                                        htmlContent={item.description}
                                      />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    )}
                </Container>
              </section>
            )}
          {findUsGoogleMapSection &&
            !checkPropertiesForNull(findUsGoogleMapSection, ["heading"]) && (
              <section className="pb-5 pb-lg-7">
                <Container>
                  <Row className="">
                    <Col lg={8}>
                      {findUsGoogleMapSection?.subHeading && (
                        <p className="text-uppercase  text-secondary pb-2 mb-0 ssp-semibold">
                          {findUsGoogleMapSection?.subHeading}
                        </p>
                      )}

                      <h2 className="  text-dark-green mb-5 fs-4">
                        {findUsGoogleMapSection?.heading}
                      </h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <iframe
                        className="position-relative google-map"
                        src={findUsGoogleMapSection?.map}
                        width="100%"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
        </Layout>
      </div>
    </>
  );
};

export default HowToFindUsPage;
